import { useEffect } from 'react';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';

import { useAuth } from '@/context/auth/AuthState';
import TextInput from '@/components/Input/TextInput';
import { useForm } from 'react-hook-form';

const Login = () => {
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  const { login, user } = useAuth();
  const router = useRouter();

  async function onSubmit(formData) {
    const { email, password } = formData;

    try {
      login(email, password);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }

  useEffect(() => {
    if (user) {
      router.push('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className='flex min-h-screen flex-col justify-center  bg-gray-100 py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-gray-100 py-8 px-4 sm:rounded-xl sm:px-10'>
          <img
            src='/city-council-logo.png'
            className='mx-auto mb-3 h-20'
            alt='logo'
          />
          <h1 className=' mb-5 text-center text-sm font-medium uppercase tracking-wide text-gray-600'>
            Malé City Council
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor='email' className='mb-2 block text-xs text-gray-600'>
              Email Adress
            </label>
            <TextInput
              id='email'
              type='email'
              placeholder='email@example.com'
              name='email'
              control={control}
            />
            <label
              htmlFor='password'
              className='mb-2 block text-xs text-gray-600'
            >
              Password
            </label>
            <TextInput
              id='password'
              type='password'
              className='mb-8'
              name='password'
              control={control}
            />
            <button
              type='submit'
              className='flex w-full justify-center rounded-md border border-transparent bg-primary py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary/90 focus:outline-none'
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

const INITIAL_STATE = {
  email: '',
  password: '',
};
