import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

const TextInput = ({
  label,
  name,
  className,
  control,
  whiteVariant,
  rules,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className={`mb-3 ${className}`}>
          {label && (
            <label
              htmlFor='name'
              className={`mb-1 block text-sm text-gray-500 ${
                props.dir ? 'text-right' : 'text-left'
              }`}
            >
              {label}
            </label>
          )}
          <div
            className={`group rounded-xl border ${
              error ? 'border-red-500' : 'border-transparent'
            } ${whiteVariant ? 'bg-white' : 'bg-gray-100'}`}
          >
            <TextField
              onChange={onChange}
              value={value ?? ''}
              className='placeholder:text-gray-50'
              fullWidth
              {...props}
            />
          </div>
          {error && (
            <div className='mt-2 flex items-center text-xs text-red-500'>
              <CloseRounded className='h-4 w-4' />
              <p>{error?.message}</p>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default TextInput;
